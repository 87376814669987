import { DetailedListItem } from 'components/Utility/DetailedListItem'
import React from 'react'
import { Image } from 'react-native'
import { PublicGroupSchemeInviteDto } from 'store/dto/group-scheme.dto'
import { InviteStatus } from 'store/dto/invite.dto'
import { Paper, Sizing } from 'styles'

type SchemeInviteListItemProp = {
  schemeInvite: PublicGroupSchemeInviteDto
  onPressFunction?: () => void
}
export const SchemeInviteListItem = (props: SchemeInviteListItemProp) => {
  const { schemeInvite, onPressFunction } = props
  
  const {
    groupScheme,
    status
  } = schemeInvite || {}
  const { organizationDisplayName, organizationLogoPath } = groupScheme || {}

  const { colors: themeColors } = Paper.useAppTheme()

  const titleRight = status === InviteStatus.OPEN
    ? `Invited`
    : `Pending`


  const subTitle = status === InviteStatus.OPEN
    ? `Awaiting Your Response`
    : `Awaiting Employer Enrolment`

  const style = status === InviteStatus.OPEN
    ? { color: themeColors.accent }
    : undefined

  return (
    <DetailedListItem
      icon={<Image source={{ uri: organizationLogoPath }} style={{
        width: Sizing.x25,
        height: Sizing.x25,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />}
      onPress={onPressFunction}
      title={organizationDisplayName}
      titleStyle={style}
      subTitleStyle={style}
      titleRight={titleRight}
      titleRightStyle={style}
      subTitle={subTitle}
    />
  )
}
