import { ProcessIntroScreen } from 'components/ScreenTemplates/ProcessIntroScreen'
import { Paragraph } from 'components/Typography'
import { BulletItem } from 'components/Typography/BulletItem'
import { JAR_NAME_GROUP } from 'lib/constants'
import { goToMainAppStack, rootNavigate } from 'lib/RootNavigation'
import React from 'react'
import { View } from 'react-native'
import { useGetMeQuery } from 'store/apiSlice'
import { GroupSchemeEnrolmentDto, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'

export const SchemeEnrolmentSetupIntroScreen = ({ route, navigation }) => {
  const { enrolment }: { enrolment: PublicPendingSchemeEnrolmentDto | GroupSchemeEnrolmentDto } =  route?.params || {}

  const { data: client, isLoading: clientIsLoading, error: clientError, isFetching: clientIsFetching, refetch: refetchClient } = useGetMeQuery()

  const { groupScheme } = enrolment || {}
  const { organizationName, organizationLogoPath } = groupScheme || {}

  const isLoading = clientIsLoading
  const error: any = clientError

  const next = () => {
    rootNavigate('SchemeEnrolmentSetupProcessStack', {
      enrolment,
      client,
    })
  }

  const refetchAll = () => {
    refetchClient()
  }

  return (
    <ProcessIntroScreen
      isLoading={isLoading}
      error={error}
      errorTryAgain={refetchAll}
      buttonTitle={'Connect Now'}
      buttonAction={next}
      showButton={true}
      illustrationFilename={organizationLogoPath ? undefined : 'envelope_with_report.png'}
      imageSource={organizationLogoPath ? { uri: organizationLogoPath } : undefined}
      headline={'You have been enrolled in a workplace scheme'}
      subHeading={`You have been enrolled by ${organizationName} in their scheme`}
      cancelButtonText={'Back to Dashboard'}
      cancelButtonAction={goToMainAppStack}
    >
      <Paragraph style={{ textAlign: 'left' }}>{`Connect this employer now in just a few steps to:`}</Paragraph>
      <View style={{ alignSelf: 'center' }}>
        <BulletItem style={{ textAlign: 'left' }}>{`See how much they contribute into your ${JAR_NAME_GROUP}`}</BulletItem>
        <BulletItem style={{ textAlign: 'left' }}>{`Decide how Jarvis treats contributions for forecasting your retirement`}</BulletItem>
      </View>
    </ProcessIntroScreen>
  )
}
