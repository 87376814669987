import { useFocusEffect } from '@react-navigation/native'
import { ProcessIntroScreen } from 'components/Layout'
import { Paragraph } from 'components/Typography'
import { AppIllustration } from 'components/Utility/AppIllustration'
import { AppInfoModal } from 'components/Utility/AppInfoModal'
import { WebAppDownloadModal } from 'components/Utility/WebAppDownloadModal'
import { changeAppContext, goToMainAppStack, rootNavigate } from 'lib/RootNavigation'
import { userIsReady } from 'lib/authHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import React, { useEffect, useState } from 'react'
import { Image, View } from 'react-native'
import { Portal } from 'react-native-paper'
import { useSelector } from 'react-redux'
import { useGetCurrentUserQuery, useGetMeQuery, useGetPendingEnrolmentByIdQuery } from 'store/apiSlice'
import { AppContext, setLoginParams } from 'store/authSlice'
import { useAppDispatch } from 'store/hooks'
import { Sizing } from 'styles'

const isWeb = platformIsWeb()

export const EntryPointSchemeEnrolmentScreen = ({ route, navigation }) => {
  const { preferredSchemeEnrolmentId } = route.params || {}

  const [showDownloadModal, setShowDownloadModal] = useState(null)
  const [showInfoModal, setShowInfoModal] = useState(false)

  const dispatch = useAppDispatch()

  const { data: pendingEnrolment, error: pendingEnrolmentError, isLoading: pendingEnrolmentIsLoading, isFetching: pendingEnrolmentIsFetching } = useGetPendingEnrolmentByIdQuery(preferredSchemeEnrolmentId, { skip: !preferredSchemeEnrolmentId })
  const authUser = useSelector((state: any) => state.auth.user)
  const authUserReady = userIsReady(authUser)
  const { data: user, isLoading: userIsLoading, isFetching: userIsFetching, error: userError } = useGetCurrentUserQuery(undefined, { skip: !authUserReady})
  const { data: client, isLoading: clientIsLoading, error: clientError, isFetching: clientIsFetching, refetch: refetchClient } = useGetMeQuery(undefined, { skip: !user })

  const { groupScheme } = pendingEnrolment || {}
  const { organizationDisplayName, organizationLogoPath } = groupScheme || {}

  //Hook to force user context on entry
  useFocusEffect(() => {
    changeAppContext(AppContext.CLIENT, user, dispatch, false)
  })

  //Set loginParams emailAddress when get an pendingEnrolment without a user
  useEffect(() => {
    if (pendingEnrolment) {
      dispatch(setLoginParams({
        emailAddress: pendingEnrolment?.employeeEmail,
      }))
    }
  }, [pendingEnrolment])
  
  const isLoading = pendingEnrolmentIsLoading || pendingEnrolmentIsFetching || userIsLoading || userIsFetching || clientIsLoading || clientIsFetching

  const everythingReady = !!user && !!client && !!pendingEnrolment

  return (
    <ProcessIntroScreen
      isLoading={isLoading}
      buttonTitle={
        everythingReady && !isWeb ? `Connect Employer`
        : user ? `Back to Jarvis` 
        : `Get Started`}
      buttonAction={
        everythingReady && !isWeb ? () => rootNavigate('SchemeEnrolmentSetupIntroScreen', { enrolment: pendingEnrolment })
        : user ? goToMainAppStack
        : () => rootNavigate('NewUserCarouselScreen')
      }
      showButton={true}
      cancelButtonText={
        everythingReady && !isWeb ? `Back to Jarvis`
        : isWeb ? `Download Mobile App`
        : undefined
      }
      cancelButtonAction={
        everythingReady && !isWeb ? goToMainAppStack
        : isWeb ? () => setShowDownloadModal(true)
        : undefined
      }
      refuseButtonAction={
        pendingEnrolment ? () => rootNavigate('EnrolmentOptOutIntroScreen', { enrolmentId: pendingEnrolment?.id })
        : undefined
      }
      refuseButtonText={
        pendingEnrolment ? `Looking to Opt Out Instead?`
        : undefined
      }
      imageSource={require('assets/brand/jarvis-logo-blk-4x.png')}
      imageSize={Sizing.x50}
      imageAction={() => setShowInfoModal(true)}
      headline={pendingEnrolment
        ? `Welcome ${pendingEnrolment?.metadata?.firstName} ${pendingEnrolment?.metadata?.surname}!`
        : `Sorry, your link is no longer valid`
      }
      subHeading={pendingEnrolment
        ? `You've been enrolled in a Jarvis Workplace Pension`
        : `You may have already linked your employer to your Jarvis account`
      }
    >
      <View style={{ paddingVertical: Sizing.x30 }}>
        {
          organizationLogoPath
            ? <Image
                source={{ uri: organizationLogoPath }}
                style={{
                  width: Sizing.x200,
                  height: Sizing.x200,
                  resizeMode: 'contain',
                  alignSelf: 'center',
                }}
              />
            : <AppIllustration filename={'store_retirement_meter.png'} style={{
                width: Sizing.x200,
                height: Sizing.x200,
                resizeMode: 'contain',
                alignSelf: 'center',
              }} />
        }
      </View>
      <Paragraph>{
        pendingEnrolment ? `${organizationDisplayName} has enrolled you in their workplace scheme`
        : isWeb ? `You can still create a Jarvis account here and we'll give you the links to download our app for Android or iOS.`
        : `You can still create a Jarvis account and enjoy all the benefits of knowing exactly when you can retire.`
      }</Paragraph>
      <Portal>
        <AppInfoModal
          user={user}
          client={client}
          visible={showInfoModal}
          onDismiss={() => setShowInfoModal(false)}
          isUnauthenticated={!user}
        />
      </Portal>
      {
        isWeb
          ? <Portal>
              <WebAppDownloadModal
                visible={showDownloadModal}
                onDismiss={() => setShowDownloadModal(false)}
              />
            </Portal>
          : <></>
      }
    </ProcessIntroScreen>
  )
}
