import { ProcessIntroScreen } from 'components/ScreenTemplates/ProcessIntroScreen'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { goToLogout, rootNavigate } from 'lib/RootNavigation'
import React from 'react'
import { useGetAffiliateByCodeQuery, useGetCurrentUserQuery, useGetOpenInviteByIdQuery, useGetOpenSchemeInviteByIdQuery, useGetPendingEnrolmentByIdQuery } from 'store/apiSlice'
import { ClientMeDto } from 'store/dto/client.dto'
import { UserMetadata } from 'store/dto/user.dto'
import { concat, pick } from 'lodash'

export const ClientSetupIntroScreen = ({ route, navigation }) => {  
  const { onStart, onFinish, existingClient }: { onStart: any, onFinish: any, existingClient: ClientMeDto } = route?.params || {}

  const { data: user, isLoading: userIsLoading, isFetching: userIsFetching, error: userError } = useGetCurrentUserQuery()
  const { data: affiliate, isLoading: affiliateIsLoading, error: affiliateError, refetch: refetchAffiliate } = useGetAffiliateByCodeQuery(user?.affiliateCode, { skip: !user?.affiliateCode } )
  const { data: openInvite, isLoading: openInviteIsLoading, error: openInviteError, refetch: refetchInvite } = useGetOpenInviteByIdQuery(user?.preferredInviteId, { skip: !user?.preferredInviteId })
  const { data: openSchemeInvite, isLoading: openSchemeInviteIsLoading, error: openSchemeInviteError } = useGetOpenSchemeInviteByIdQuery(user?.preferredSchemeInviteId, { skip: !user?.preferredSchemeInviteId })
  const { data: pendingEnrolment, error: pendingEnrolmentError, isLoading: pendingEnrolmentIsLoading } = useGetPendingEnrolmentByIdQuery(user?.preferredSchemeEnrolmentId, { skip: !user?.preferredSchemeEnrolmentId })

  const metadata: UserMetadata =
    existingClient ? pick(existingClient, ['firstName', 'surname', 'title', 'gender', 'birthDate'])
    : openInvite?.metadata || openSchemeInvite?.metadata || pendingEnrolment?.metadata

  const haveAllMetadata = metadata?.firstName
    && metadata?.surname
    && metadata?.title
    && metadata?.gender
    && metadata?.birthDate

  const isLoading = userIsLoading || userIsFetching || affiliateIsLoading || openInviteIsLoading || openSchemeInviteIsLoading || pendingEnrolmentIsLoading
  //No longer dependent on all errors, as these mean that any "bad" identifier causes a 404 and an error page
  // const error: any = affiliateError || openInviteError || openSchemeInviteError || pendingEnrolmentError || userError
  const error: any = userError

  const next = () => {
    if (onStart) {
      onStart()
    }
    rootNavigate('ClientSetupProcessStack', {
      ...route?.params,
      user,
      existingClient,
      openInvite,
      openSchemeInvite,
      pendingEnrolment,
    })
  }

  const logo =
    haveAllMetadata ?
      existingClient && affiliate ? affiliate?.logo
      : openInvite ? undefined
      : openSchemeInvite ? openSchemeInvite?.groupScheme?.organizationLogoPath
      : pendingEnrolment ? pendingEnrolment?.groupScheme?.organizationLogoPath
      : undefined
    : undefined

  const subHeading =
    haveAllMetadata ?
      existingClient && affiliate ? `Let's check the personal details that ${affiliate?.name} gave us`
      : openInvite ? `Let's check the personal details that ${openInvite?.from?.firstName} gave us`
      : openSchemeInvite ? `Let's check the personal details that ${openSchemeInvite?.groupScheme?.organizationDisplayName} gave us`
      : pendingEnrolment ? `Let's check the personal details that ${pendingEnrolment?.groupScheme?.organizationDisplayName} gave us`
      : `Let's check your personal details`
    : `Tell us a bit about yourself so we can start tailoring your Jarvis experience`

  return (
    <ProcessIntroScreen
      isLoading={isLoading}
      error={error}
      errorTryAgain={affiliateError ? refetchAffiliate : refetchInvite}
      buttonTitle={'Get Started'}
      buttonAction={next}
      showButton={true}
      illustrationFilename={logo ? undefined : 'man.png'}
      imageSource={logo ? { uri: logo } : undefined}
      headline={`Let's get to know each other`}
      subHeading={subHeading}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.PERSONAL_INFORMATION} buttonTitle={'Learn More'}/>}
      cancelButtonAction={goToLogout}
      cancelButtonText={'Logout'}
    >
    </ProcessIntroScreen>
  )
}
