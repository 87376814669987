import { ProcessIntroScreen } from 'components/ScreenTemplates/ProcessIntroScreen'
import { Paragraph } from 'components/Typography'
import { goToDashboard, goToMainAppStack, rootNavigate } from 'lib/RootNavigation'
import React from 'react'
import { useGetCurrentUserQuery, useGetMeQuery, useGetOpenSchemeInviteByIdQuery } from 'store/apiSlice'
import { PublicGroupSchemeInviteDto } from 'store/dto/group-scheme.dto'
import { InviteStatus } from 'store/dto/invite.dto'
import { Typography } from 'styles'

export const SchemeInviteAcceptIntroScreen = ({ route, navigation }) => {
  const { schemeInvite }: { schemeInvite: PublicGroupSchemeInviteDto } =  route?.params || {}

  const { data: user, error: userError, isLoading: userIsLoading, refetch: refetchUser } = useGetCurrentUserQuery()
  const { data: client, isLoading: clientIsLoading, error: clientError, isFetching: clientIsFetching, refetch: refetchClient } = useGetMeQuery()

  const { groupScheme } = schemeInvite || {}
  const { organizationName, organizationLogoPath } = groupScheme || {}

  const isLoading = userIsLoading || clientIsLoading
  const error: any = userError || clientError

  const ready = schemeInvite.status === InviteStatus.OPEN

  const next = () => {
    rootNavigate('SchemeInviteAcceptProcessStack', {
      schemeInvite,
      client,
    })
  }

  const refetchAll = () => {
    refetchUser()
  }

  return (
    <ProcessIntroScreen
      isLoading={isLoading}
      error={error}
      errorTryAgain={refetchAll}
      buttonTitle={ready ? 'Respond Now' : 'Back to Dashboard'}
      buttonAction={ready ? next : goToDashboard}
      showButton={true}
      illustrationFilename={organizationLogoPath ? undefined : 'envelope_with_report.png'}
      imageSource={organizationLogoPath ? { uri: organizationLogoPath } : undefined}
      headline={ready
        ? 'You have been invited to a workplace scheme'
        : `This invitation is no longer available`
      }
      subHeading={ready
        ? `You have been invited by ${organizationName} to join their workplace pension scheme`
        : `The invite may have been revoked or is expired`
      }
      cancelButtonText={'Back to Dashboard'}
      cancelButtonAction={goToMainAppStack}
    >
      {
        !ready ?
        <>
          <Paragraph style={Typography.fontWeight.bold}>{`Expecting to see an invite?`}</Paragraph>
          <Paragraph>{`Please check with your employer that they have invited you to join, and ask then to resend the invite.`}</Paragraph>
        </>
        : <></>
      }
    </ProcessIntroScreen>
  )
}
