import { AutoCompleteItem, ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedDateInput } from 'components/Inputs/ManagedDateInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { ModalEditScreen } from 'components/Layout/ModalEditScreen'
import { ModalEditWrap } from 'components/Layout/ModalEditWrap'
import { Paragraph, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { addDays, addYears } from 'date-fns'
import { unformatNationalInsuranceNumber } from 'lib/clientHelpers'
import { JAR_NAME_ALL, MAX_CLIENT_AGE, MIN_CLIENT_AGE, NATIONAL_INSURANCE_NO_MASK, NINO_REGEX } from 'lib/constants'
import { formatUkDate, getActionDate } from 'lib/dateHelpers'
import { featureIsAvailable } from 'lib/featureHelpers'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { EmployerAppNavScreen } from 'lib/navigationHelpers'
import { employerAppNavigate } from 'lib/RootNavigation'
import { default as React, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAddGroupSchemeJobWithDataMutation, useGetGroupSchemeMembersQuery, useGetPensionBrandsQuery, useGetPensionProvidersQuery, useGetUserFeaturesQuery } from 'store/apiSlice'
import { Address, Gender, Title } from 'store/dto/base.dto'
import { FeatureCode } from 'store/dto/feature.dto'
import { CreateGroupSchemeJobMemberDto, GroupSchemeEnrolmentNoNinoReason, GroupSchemeJobDataSetFormat, GroupSchemeJobType } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentGroupScheme, setShowEmployerAddEnrolmentJobVisible, setSnackbarData, showEmployerAddEnrolmentJobVisible } from 'store/uxSlice'

export const EmployerAddEnrolmentJobModal = () => {
  return (
    <ModalEditWrap
      screen={<ScreenContent />}
    />
  )
}

const ScreenContent = () => {
    const dispatch = useAppDispatch()
    
    const currentScheme = useAppSelector(currentGroupScheme)
    const { previousPensionProviderId } = currentScheme || {}
    // const [cursorPosition, setCursorPosition] = useState(0)
  
    const { data: features, error: featuresError, isLoading: featuresIsLoading, refetch: refetchFeatures } = useGetUserFeaturesQuery()
    const { data: providers, error: providersError, isLoading: providersIsLoading, refetch: refetchProviders } = useGetPensionProvidersQuery(undefined, { skip: !previousPensionProviderId })
    const { data: brands, error: brandsError, isLoading: brandsIsLoading, refetch: refetchBrands } = useGetPensionBrandsQuery(undefined, { skip: !previousPensionProviderId })
  
    const [addMemberJob, { data: addedJob, isLoading: addJobIsLoading, error: addJobError, reset: addJobReset }] = useAddGroupSchemeJobWithDataMutation()
  
    const [allDataLoaded, setAllDataLoaded] = useState(false)
    const [noNinoMode, setNoNinoMode] = useState(false)
    const [noOptOutWindowDateMode, setNoOptOutWindowDateMode] = useState(false)
    const [showNoOptOutWindowTransitionDialog, setShowNoOptOutWindowTransitionDialog] = useState(false)
    const [showNoNinoTransitionDialog, setShowNoNinoTransitionDialog] = useState(false)
    const [showNoNinoSubmitDialog, setShowNoNinoSubmitDialog] = useState(false)
    const [niNumberToCheck, setNiNumberToCheck] = useState(undefined)
    const [matchingMembers, setMatchingMembers] = useState(undefined)
  
    const { data: members, isLoading: membersIsLoading, error: membersError, isFetching: membersIsFetching, refetch: refetchMembers } = useGetGroupSchemeMembersQuery({
      groupSchemeId: currentScheme?.id,
      nationalInsuranceNo: niNumberToCheck
    }, { skip: !niNumberToCheck})
    
    const formObj = useForm<{
      nationalInsuranceNo?: string
      nationalInsuranceNoNotProvidedReason?: GroupSchemeEnrolmentNoNinoReason
      employeeEmail: string
      employeeId?: string
      title?: Title
      firstName: string
      surname: string
      gender: Gender
      birthDate: string
      addressBuilding: string
      addressStreet1: string
      addressTown: string
      addressPostCode: string
      employmentStartDate?: string
      enrolmentDate?: string
      autoEnrolmentWindowOptOutDate?: string
      previousPensionProviderReference?: string
    }>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: {
        nationalInsuranceNo: '',
        employeeEmail: '',
        employeeId: '',
        firstName: '',
        surname: '',
        birthDate: '',
        addressBuilding: '',
        addressStreet1: '',
        addressTown: '',
        addressPostCode: '',
      },
    })
    //Form refs for focussing
    const nationalInsuranceNoRef = useRef(null)
    const employeeEmailRef = useRef(null)
    const employeeIdRef = useRef(null)
    const firstNameRef = useRef(null)
    const surnameRef = useRef(null)
    const birthDateRef = useRef(null)
    const addressBuildingRef = useRef(null)
    const addressStreet1Ref = useRef(null)
    const addressTownRef = useRef(null)
    const addressPostCodeRef = useRef(null)
    const employmentStartDateRef = useRef(null)
    const enrolmentDateRef = useRef(null)
    const autoEnrolmentWindowOptOutDateRef = useRef(null)
    const previousPensionProviderReferenceRef = useRef(null)

    //setAllDataLoaded when queries finished loading
    useEffect(() => {
      if (!featuresIsLoading && !providersIsLoading && !brandsIsLoading) {
        setAllDataLoaded(true)
      }
    }, [featuresIsLoading, providersIsLoading, brandsIsLoading])
  
    //Show required fields on load
    useEffect(() => {
      if (allDataLoaded) {
        trigger()
      }
    }, [allDataLoaded])
  
    const { handleSubmit, setValue, setError, trigger, watch, formState: { isDirty, isValid, errors } } = formObj

    const onSubmit = async attributes => {
      setShowNoNinoSubmitDialog(false)
      const { nationalInsuranceNo, nationalInsuranceNoNotProvidedReason, autoEnrolmentWindowOptOutDate, addressBuilding, addressStreet1, addressTown, addressPostCode, ...remaining } = attributes
      const addressDetail: Address = {
        building: addressBuilding,
        street1: addressStreet1,
        town: addressTown,
        postCode: addressPostCode,
        country: 'GB',
      } 
      const data: CreateGroupSchemeJobMemberDto = {
        jobType: GroupSchemeJobType.MEMBER,
        groupSchemeId: currentScheme.id,
        memberRecords: [
          {
            nationalInsuranceNo: noNinoMode ? undefined : unformatNationalInsuranceNumber(nationalInsuranceNo),
            nationalInsuranceNoNotProvidedReason: noNinoMode ? nationalInsuranceNoNotProvidedReason : undefined,
            autoEnrolmentWindowOptOutDate: noOptOutWindowDateMode ? undefined : autoEnrolmentWindowOptOutDate,
            addressDetail,
            ...remaining,
          }
        ]
      }
      addMemberJob(data)
    }
  
    const nationalInsuranceNo = watch('nationalInsuranceNo')
    const autoEnrolmentWindowOptOutDate = watch('autoEnrolmentWindowOptOutDate')
    const enrolmentDate = watch('enrolmentDate')

    //Trigger validation when noNinoMode or noOptOutWindowDateMode changes
    useEffect(() => {
      trigger()
    }, [noNinoMode, noOptOutWindowDateMode])
    
    //Update niNumberToCheck when value changes
    useEffect(() => {  
      setMatchingMembers(null)
      setNiNumberToCheck(nationalInsuranceNo && nationalInsuranceNo.length === NATIONAL_INSURANCE_NO_MASK.length ? unformatNationalInsuranceNumber(nationalInsuranceNo) : undefined)
    }, [nationalInsuranceNo])
  
    //Force refetch when NI number to check changes
    //NOTE: Without doing this, there seems to be a race condition of some kind with form error
    //states becoming out of sync, resulting in the error messages not being displayed consistently
    useEffect(() => {  
      if (niNumberToCheck) {
        refetchMembers()
      }    
    }, [niNumberToCheck])
  
    //Update matchingMembers based on check result
    useEffect(() => {  
      if (membersError || membersIsLoading || membersIsFetching) {
        setMatchingMembers(null)
      } else if (members) {
        setMatchingMembers(members)
      }
    }, [members, membersError, membersIsLoading, membersIsFetching])
  
    //Trigger validation when available changes
    useEffect(() => {
      if (matchingMembers !== undefined) {
        trigger('nationalInsuranceNo')
      }
    }, [matchingMembers])
  
    useEffect(() => {
      if (addedJob) {
        dispatch(setSnackbarData({
          message: `Member New Enrolment Job added!`,
          subMessage: `We'll send you a message when it has been processed${addedJob?.expectedCompleteAt ? ` (estimated completion at ${getActionDate(addedJob?.expectedCompleteAt )})` : ``}`,
          iconName: 'check-circle-outline',
          duration: 5000,
        }))
        close()
      }
    }, [addedJob])

    const handleNoOptOutWindowTransitionConfirm = () => {
      setShowNoOptOutWindowTransitionDialog(false)
      setNoOptOutWindowDateMode(true)
    }

    const handleNoNinoTransitionConfirm = () => {
      setShowNoNinoTransitionDialog(false)
      setNoNinoMode(true)
    }

    const close = () => {
      dispatch(setShowEmployerAddEnrolmentJobVisible(false))
    }
  
    const handleGoToSearch = () => {
      employerAppNavigate(EmployerAppNavScreen.MEMBERS)
      close()
    }
  
    const isLoading = addJobIsLoading || featuresIsLoading || providersIsLoading || brandsIsLoading
    const error: any = addJobError || featuresError || providersError || brandsError
  
    const isValidNiNumber = (value: string) => {
      if (value === undefined) {
        return true
      }
      const result = value.match(NINO_REGEX)
      return result ? true : 'Invalid NI Number - please double check'
    }
  
    const isNotEnrolledNiNumber = () => {
      if (matchingMembers === null) {
        return 'Validating...'
      }
      return matchingMembers && matchingMembers?.length === 0 ? true : `Existing scheme member exists for this National Insurance Number.`
    }

    const nationalInsuranceNoNotProvidedReasonOptions: AutoCompleteItem[] = [
      {
        value: GroupSchemeEnrolmentNoNinoReason.AWAITING_PROVISION,
        label: GroupSchemeEnrolmentNoNinoReason.AWAITING_PROVISION,
        description: `The member is awaiting provision from the UK Goverment`,
      },
      {
        value: GroupSchemeEnrolmentNoNinoReason.NOT_PROVIDED,
        label: GroupSchemeEnrolmentNoNinoReason.NOT_PROVIDED,
        description: `The member has a National Insurance Number, but has not yet provided it`,
      },
      {
        value: GroupSchemeEnrolmentNoNinoReason.OTHER,
        label: GroupSchemeEnrolmentNoNinoReason.OTHER,
        description: `Any other reason`,
      },
    ]

    const enrolWithoutNinoIsAvailable = featureIsAvailable(features, FeatureCode.ENROL_NO_NINO)

    const previousProvider = previousPensionProviderId && providers ? providers.find(provider => {
      return provider.id === previousPensionProviderId
    }) : undefined
  
    const previousProviderBrand = previousProvider && brands ? brands.find(brand => {
      return brand.key === previousProvider?.primaryBrandKey
    }) : undefined

    return (
      <ModalEditScreen
        formTitle={'Add New Member'}
        onDismiss={close}
        isDirty={isDirty}
        dismissDialogText={'Discard new enrolment?'}
        error={error}
        errorTryAgain={
          addJobError ? handleSubmit(onSubmit)
          : featuresError ? refetchFeatures
          : providersError ? refetchProviders
          : brandsError ? refetchBrands
          : undefined
        }
        errorCancel={addJobError ? addJobReset : close}
        isLoading={isLoading}
        loadingMessage={addJobIsLoading ? ['Creating enrolment job...'] : undefined}
        buttonTitle={'Submit New Enrolment'}
        buttonAction={noNinoMode ? () => setShowNoNinoSubmitDialog(true) : handleSubmit(onSubmit)}
        showButton={true}
        enableButton={isDirty && isValid}
      >
        <Subheading>{`This form allows you to request enrolment of a new member into your scheme.`}</Subheading>
        <Paragraph>{`It should only be used to register new members, who have never been enrolled in the scheme, based on their National Insurance Number.`}</Paragraph>
        <Paragraph>{`If you do not have a National Insurance Number for the member, you are strongly encouraged to postpone enrolment until one is available, however you may use this form to enrol a member without one.`}</Paragraph>
        <Paragraph>{`If you need to update the details of an existing member, or change their enrolment status, please first find the member and use the appropriate form.`}</Paragraph>
        
        <Button
            onPress={handleGoToSearch}
            mode={'text'}
          >
            {`Go to Member Search`}
        </Button>
        <ContentDivider />
        <Subheading>{'Identification'}</Subheading>
        
        {
          noNinoMode
            ? <Button
                onPress={() => setNoNinoMode(false)}
                mode={'text'}
              >
                {`Add National Insurance Number`}
              </Button>
            : <></>
        }
        {
          noNinoMode
            ? <ManagedAutoCompleteInput
                name={'nationalInsuranceNoNotProvidedReason'}
                formObj={formObj}
                label={'Reason for no National Insurance Number'}
                selectOnlyMode={true}
                dataSet={nationalInsuranceNoNotProvidedReasonOptions}
                required={noNinoMode}
              />
            : <ManagedTextInput
                ref={nationalInsuranceNoRef}
                name={'nationalInsuranceNo'}
                formObj={formObj}
                label={'National Insurance Number'}
                mask={{
                  type: 'custom',
                  options: {
                    mask: NATIONAL_INSURANCE_NO_MASK,
                  }
                }}
                // onSelectionChange={(event) => {
                //   if (event?.nativeEvent?.selection?.start) {
                //     setCursorPosition(event?.nativeEvent?.selection?.start)
                //   }
                // }}
                // keyboardType={cursorPosition < 2 || cursorPosition > 10 ? 'default' : 'numeric'}
                // autoCapitalize={cursorPosition < 2 || cursorPosition > 10 ? 'characters' : undefined}
                autoCapitalize={'characters'}
                autoFocus={true}
                forceCapitals={true}
                returnKeyType={'next'}
                submitHandler={() => employeeEmailRef.current?.focus()}
                blurOnSubmit={false}
                rules={{
                  required: !noNinoMode,
                  minLength: {
                    value: NATIONAL_INSURANCE_NO_MASK.length,
                    message: 'Must be exactly 9 characters'
                  },
                  maxLength: {
                    value: NATIONAL_INSURANCE_NO_MASK.length,
                    message: 'Must be exactly 9 characters'
                  },
                  validate: {
                    isValidNiNumber,
                    isNotEnrolledNiNumber,
                  }
              }} />
        }
        {
          noNinoMode
            ? <></>
            : nationalInsuranceNo
            ? <></>
            : enrolWithoutNinoIsAvailable
            ? <Button
                onPress={() => setShowNoNinoTransitionDialog(true)}
                mode={'text'}
              >
                {`No National Insurance Number Available`}
              </Button>
            : <></>
        }
        <ManagedTextInput
          ref={employeeIdRef}
          name={'employeeId'}
          formObj={formObj}
          label={'Employee ID'}
          placeholder={'Your unique payroll system identifier'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          submitHandler={() => employeeEmailRef.current?.focus()}
          rules={{
            required: noNinoMode,
            maxLength: 35,
        }}/>
        <ManagedTextInput
          ref={employeeEmailRef}
          name={'employeeEmail'}
          keyboardType='email-address'
          formObj={formObj}
          label={'Email Address'}
          placeholder={'Their email address'}
          autoCapitalize={'none'}
          autoCorrect={false}
          autoComplete={'email'}
          returnKeyType={'next'}
          submitHandler={() => firstNameRef.current?.focus()}
          blurOnSubmit={false}
          rules={{
            required: true,
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Invalid email address"
            },
            maxLength: 100,
        }} />
        <ManagedTextInput
          ref={firstNameRef}
          name={'firstName'}
          formObj={formObj}
          label={'First Name'}
          placeholder={'Their first name'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          submitHandler={() => surnameRef.current?.focus()}
          rules={{
            required: true,
            maxLength: 40,
          }}/>
        <ManagedTextInput
          ref={surnameRef}
          name={'surname'}
          formObj={formObj}
          label={'Surname'}
          placeholder={'Their surname'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          rules={{
            required: true,
            maxLength: 40,
        }}/>
        <Subheading>{'Personal Details'}</Subheading>
        <ManagedAutoCompleteInput
          name={'title'}
          formObj={formObj}
          label={'Title'}
          selectOnlyMode={true}
          dataSet={enumToAutocompleteOptions(Title)}
          required={true}
        />
        <ManagedAutoCompleteInput
          required={true}
          formObj={formObj}
          name={'gender'}
          label={'Gender'}
          selectOnlyMode={true}
          dataSet={enumToAutocompleteOptions(Gender)}
        />
        <ManagedDateInput
          ref={birthDateRef}
          name={'birthDate'}
          formObj={formObj}
          label={'Date of Birth'}
          blurOnSubmit={false}
          required={true}
          submitHandler={() => addressBuildingRef.current?.focus()}
          mustBeInPast={true}
          notOlderThanYears={MAX_CLIENT_AGE}
          notYoungerThanYears={MIN_CLIENT_AGE}
          tryParseTwoDigitYear={true}
          showCurrentAgeMessage={true}
        />
        <Subheading>{'Address'}</Subheading>
        <ManagedTextInput
          ref={addressBuildingRef}
          name={`addressBuilding`}
          formObj={formObj}
          label={'Building Number/Name'}
          placeholder={'Enter building number/name'}
          returnKeyType={'next'}
          autoCapitalize={'words'}
          submitHandler={() => addressStreet1Ref.current?.focus()}
          blurOnSubmit={false}
          rules={{
            required: true,
            maxLength: 35,
          }} />
        <ManagedTextInput
          ref={addressStreet1Ref}
          name={`addressStreet1`}
          formObj={formObj}
          label={'Street'}
          placeholder={'Enter street'}
          returnKeyType={'next'}
          autoCapitalize={'words'}
          submitHandler={() => addressTownRef.current?.focus()}
          blurOnSubmit={false}
          rules={{
            required: true,
            maxLength: 35,
          }} />
        <ManagedTextInput
          ref={addressTownRef}
          name={`addressTown`}
          formObj={formObj}
          label={'Town'}
          placeholder={'Enter town/locality'}
          returnKeyType={'next'}
          autoCapitalize={'words'}
          submitHandler={() => addressPostCodeRef.current?.focus()}
          blurOnSubmit={false}
          rules={{
            required: true,
            maxLength: 35,
          }} />
        <ManagedTextInput
          ref={addressPostCodeRef}
          name={`addressPostCode`}
          formObj={formObj}
          label={'Post Code'}
          placeholder={'Enter postcode'}
          autoCapitalize={'characters'}
          returnKeyType={'next'}
          submitHandler={() => previousPensionProviderReferenceRef.current?.focus()}
          rules={{
            required: true,
            maxLength: 10,
          }} />
        {
          previousProviderBrand
            ? <>
                <Subheading>{'Previous Pension Details'}</Subheading>
                <Paragraph>{`If you have the member's pension reference number for any previous pension they held with ${previousProviderBrand.name}, entering it here will allow the member to more eaily consolidate that pension into their ${JAR_NAME_ALL}.`}</Paragraph>
                <ManagedTextInput
                  ref={previousPensionProviderReferenceRef}
                  name={`previousPensionProviderReference`}
                  formObj={formObj}
                  label={'Previous Pension Member Reference'}
                  placeholder={`Enter member's previous pension reference number`}
                  autoCapitalize={'characters'}
                  returnKeyType={'next'}
                  submitHandler={() => employmentStartDateRef.current?.focus()}
                  rules={{
                    required: false,
                    minLength: 3,
                  }} />
              </>
            : <></>
        }
        <Subheading>{'Employment/Enrolment Details'}</Subheading>
        <ManagedDateInput
          ref={employmentStartDateRef}
          name={'employmentStartDate'}
          formObj={formObj}
          label={'Employment Start Date'}
          blurOnSubmit={false}
          required={false}
          notBeforeDate={new Date('1900-01-01')}
          notAfterDate={addYears(new Date(), 1)}
          submitHandler={() => enrolmentDateRef.current?.focus()}
        />
        <ManagedDateInput
          ref={enrolmentDateRef}
          name={'enrolmentDate'}
          formObj={formObj}
          label={'Pension Scheme Enrolment Date'}
          blurOnSubmit={false}
          required={noOptOutWindowDateMode}
          notBeforeDate={new Date('1900-01-01')}
          notAfterDate={addYears(new Date(), 1)}
          submitHandler={noOptOutWindowDateMode ? undefined : () => autoEnrolmentWindowOptOutDateRef.current?.focus()}
          informationMessage={noOptOutWindowDateMode && enrolmentDate
            ? `Auto Enrolment Opt Out Window will end after 32 days on ${formatUkDate(addDays(new Date(enrolmentDate), 32))}`
            : undefined
          }
        />
        {
          noOptOutWindowDateMode ? <></> : 
            <ManagedDateInput
              ref={autoEnrolmentWindowOptOutDateRef}
              name={'autoEnrolmentWindowOptOutDate'}
              formObj={formObj}
              label={'Auto Enrolment Opt-Out Window End Date'}
              blurOnSubmit={false}
              required={!noOptOutWindowDateMode}
              notBeforeDate={new Date('1900-01-01')}
              notAfterDate={addYears(new Date(), 1)}
            />
        }
        {
          noOptOutWindowDateMode
            ? <Button
                onPress={() => setNoOptOutWindowDateMode(false)}
                mode={'text'}
              >
                {`Set Opt Out Window End Date`}
              </Button>
            : autoEnrolmentWindowOptOutDate
            ? undefined
            : <Button
                onPress={() => setShowNoOptOutWindowTransitionDialog(true)}
                mode={'text'}
              >
                {`I don't know the Opt Out Window End Date`}
              </Button>
        }
        <ConfirmationDialog
          visible={showNoOptOutWindowTransitionDialog}
          onCancel={() => setShowNoOptOutWindowTransitionDialog(false)}
          title={'Are you sure?'}
          content={`If you do not know the end date for the member's auto enrolment Opt Out period, we can automatically calculate it for you from the Pension Scheme Enrolment Date.\n\nYWe will calculate the end of their Opt Out period to be 32 days after the enrolment date you enter.\n\nYou should ensure that you continue to meet your legal requirements and remain compliant with Auto Enrolment legislation regarding the length of time that an enrolled member is provided during which they can choose to Opt Out.`}
          cancelLabel={'Cancel'}
          confirmLabel={'I understand'}
          onConfirm={handleNoOptOutWindowTransitionConfirm}
        />
        <ConfirmationDialog
          visible={showNoNinoTransitionDialog}
          onCancel={() => setShowNoNinoTransitionDialog(false)}
          title={'Are you sure?'}
          content={`Enrolling a member without a National Insurance Number should only be used as a last resort.\n\nIf possible, postpone the enrolment of the member until you have received their National Insurance Number.\n\nMembers enrolled without National Insurance Numbers will receive a reduced feature set in the Jarvis app until it is provided.`}
          cancelLabel={'Cancel'}
          confirmLabel={'I understand'}
          onConfirm={handleNoNinoTransitionConfirm}
        />
        <ConfirmationDialog
          visible={showNoNinoSubmitDialog}
          onCancel={() => setShowNoNinoSubmitDialog(false)}
          title={'Are you sure?'}
          content={`Enrol this member without a National Insurance Number?\n\nWhen you receive their National Insurance Number, you will need to submit an enrolment update to add it.`}
          cancelLabel={'Cancel'}
          confirmLabel={'Enrol Member'}
          onConfirm={handleSubmit(onSubmit)}
        />
      </ModalEditScreen>
    )
  }
